.accreditation-section {
    padding: 4rem 1rem;

    margin: 0 auto;
    background-color: #010A13;
    width: 100%;
  }
  
  .accreditation-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .accreditation-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
  }
  
  .title-normal {
    color: #eaeaea;
  }
  
  .title-highlight {
    background: linear-gradient(90deg, #15f5b9d0, #002affc4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

  
  .accreditation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
  }
  
  .accreditation-card {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 102, 255, 0.1);
    transition: all 0.3s ease;
  }
  .accreditation-footer {
    text-align: center;
    margin-top: 40px;
  }
  
  .footer-text {
    text-align: center;
    margin-top: 10px;
    font-size: 1.15rem;
    color: #ffffff;
    
  }
  
  /* .accreditation-card:hover {
    box-shadow: 0 8px 12px rgba(0, 102, 255, 0.3);
    transform: translateY(-2px);
  } */
  
  .card-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 102, 255, 0.05));
    transition: all 0.3s ease;
  }
  
  .accreditation-card:hover .card-overlay {
    background: linear-gradient(to bottom, transparent, rgba(0, 102, 255, 0.1));
  }
  
  .card-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .card-image {
    width: 128px;
    height: 128px;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    transition: color 0.3s ease;
    text-align: center;
  }
  
  .accreditation-card:hover .card-title {
    color: #0066FF;
  }
  
  .card-description {
    font-size: 0.875rem;
    color: #ffffff;
    text-align: center;
    margin-top: 0.5rem;
  }
  
  /* Media query for desktop layout */
  @media (min-width: 768px) {
    .accreditation-container {
      flex-direction: row;
      justify-content: center;
      gap: 4rem;
    }
  }
  
  @media (min-width: 576px) {
    .accreditation-container {
        flex-direction: row;
        justify-content: center;
        gap: 2rem; /* Adjust gap for slightly larger screens */
    }
}

@media (max-width: 575px) {
    .accreditation-container {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem; /* Adjust gap for very small screens */
    }
    .card-content {
        padding: 3.5rem; /* Adjust padding for smaller screens */
    }

    .accreditation-card {
        width: 100%; /* Full width for mobile devices */
    }

    .card-title {
        font-size: 1rem; /* Maintain smaller size */
    }

    .footer-text {
        font-size: 0.9rem; /* Slightly smaller footer text */
    }
}