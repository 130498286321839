.Award-Container-section
{
    background: linear-gradient(to right, #010b14, #000000);

}
.Award-Container-section h1 {
    position: relative;
    z-index: 1;
    background: linear-gradient(90deg, #15f5b9d0, #002affc4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    text-align: center;
    padding-top: 20px;
    font-size: 2em; /* default size */
}

.Award-Container-section p {
    color: white;
    text-align: center;
    font-size: 1em; 
    padding-left:5px;
    padding-right:5px;
    margin-bottom:10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .Award-Container-section h1 {
        font-size: 1.5em;
        padding-top: 15px;
    }
    
    .Award-Container-section p {
        font-size: 0.9em;
    }
}

.slideshow-container-honors {
  position: relative;
  width: 90%; /* Full width with some margin */
  max-width: 1200px; /* Maximum width for larger screens */
  height: 60vh;
  margin: auto;
  overflow: hidden;
}

.slide-honors {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.logos-honors {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 logos per row */
  border-top: 1px solid #e1d5d5;
  border-right: 1px solid #e1d5d5;
  background-color:white;
}

.logo-item-honors {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #e1d5d5;
  border-bottom: 1px solid #e1d5d5;
}

.logo-image-honors {
  width: 100%; /* Ensure logos fill their container */
  height: auto; /* Maintain aspect ratio */
  max-width: 150px; /* Increased max width for larger logos */
  transition: transform 0.3s ease;
}

.button-honors {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  border: none;
  padding: 12px 16px; /* Larger padding */
  cursor: pointer;
  border-radius: 5px;
  background:none!important;
  font-size: 16px; /* Font size for buttons */
}

.prev-honors {
  left: 10px;
}
 .arrow-honors
{
  color: #01baef  ;
}

.next-honors {
  right: 10px;
 
}


/* Responsive styles */
@media (max-width: 1200px) {
  .logos-honors {
    grid-template-columns: repeat(4, 1fr); /* 4 logos per row on medium screens */
  }

  .logo-image-honors {
    max-width: 100px; /* Adjust max width for medium screens */
  }
}

@media (max-width: 800px) {
  .logos-honors {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on smaller screens */
  }

  .logo-image-honors {
    max-width: 80px; /* Adjust max width for smaller screens */
  }

  .button-honors {
    font-size: 14px; /* Smaller font size for buttons on small screens */
    padding: 10px; /* Smaller padding for buttons */
  }
}

@media (max-width: 500px) {
  .logos-honors {
    grid-template-columns: repeat(4, 1fr); /* 2 logos per row on very small screens */
  }

  .logo-image-honors {
    max-width: 60px; /* Adjust max width for very small screens */
  }

  .button-honors {
    font-size: 12px; /* Smaller font size for buttons on very small screens */
  }
}

@media(max-width: 480px){
  .prev-honors{
    left: -10px;
  }
  .next-honors{
    right: -10px;
  }
  .Award-Container-section{
    margin-top: 5%;
  }
  .slideshow-container-honors{
    height: 40vh;
  }
}
