/* Overlay for the modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;


/* Form container */
.form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    position: relative;
    text-align: center;
}
h2 {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #00ffbbe0, #2600ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    margin-bottom: 20px;
}
p{
    color: #333;
}

/* Input fields, select dropdown, and textarea */
input, select, textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 90%;
    box-sizing: border-box;
    font-size: 16px; /* Ensure uniform font size */
}

/* Submit button */
.submit-button {
    background: linear-gradient(45deg, #15f5b9d0, #002affc4);
  color: #fff;
  padding: 2% 2%;
  font-weight: 600;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  transition: background 0.4s ease;
}
.submit-button:hover{
    background: linear-gradient(185deg, #15f5b9d0, #002affc4);
  transition: background 0.4s ease;

}

/* Close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
    font-weight: bold;
}

.close-button:hover {
    color: red;
}
}