.whyInfz {
    padding: 3% 2%;
    color: white;
    text-align: center;
    background: #000000;
    background: linear-gradient(to right, #010b14, #000000);
}

.whyInfz h1{
    font-size: 35px;
}

.info-div{
    display: flex;
    gap: 2%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.infoCard {
    margin: 3% 1%;
    padding: 20px;
    border-radius: 8px;
    background: #f9f9f9;
    position: relative;
    overflow: hidden;
    width: 300px;
    transition: all 0.3s ease;
    background-color: black;
    color: white;
    text-align: center;
    border: 1px solid rgb(143, 141, 141);
}

.infoCard h1{
    background: linear-gradient(90deg, #15F5BA, #0066ff); 
  -webkit-background-clip: text;
  color: transparent;
  font-size: 30px;
}

.infoCard h2{
    font-size: 20px;
    font-weight: 500;
}

.infoCard:hover {
    outline: 3px solid transparent;
    background-clip: padding-box;
    box-shadow: 0 0 15px rgba(21, 245, 186, 0.5), 0 0 25px rgba(0, 102, 255, 0.5); 
}

.content {
    margin-top: 10px;
    font-size: 14px;
    color: #aaa9a9;
}

/* responsiveness */
@media(max-width:400px){
    .whyInfz{
        padding: 6% 4%;
    }
    .info-div{
        display: block;
    }
    .infoCard{
        width: 100%;
        padding: 8px;
    }
    .infoCard h1{
        font-size: 25px;
    }
    .infoCard h2 {
        font-size: 17px;
        font-weight: 400;
    }
    .whyInfz h1 {
        font-size: 30px;
    }
}
