
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  /* font-family: "Karla", sans-serif; */
  
}

.anm-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: -10%;
  margin-top: -3%;
}

.homeBanner-main{
  background-image: url("../public/info-bg.jpg");
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 1%;
    padding-top: 5%;
}

/* Neon Corner Container */
.border-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  width: 250px;  /* Adjust as needed */
  height: 450px; /* Adjust as needed */
}

/* Scanning Line */
.scanning-line {
  display: block;
  position: absolute;
  left: 0;
  height: 2px;
  width: 320px;
  background-color: #1BFFFF; 
  box-shadow: 
    0 40px 40px #1BFFFF, 
    0 55px 55px #1BFFFF,
    0 80px 100px #1BFFFF;
  z-index: 3;
}

.hideScanningLine{
  display: none;
}

/* Neon Corner Borders */
.neon-corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border-color: #1BFFFF;
  /* box-shadow: 0 0 20px #00ffcc, 0 0 30px #00ffcc, 0 0 40px #00ffcc; */
}

.neon-corner::before, .neon-corner::after {
  content: '';
  position: absolute;
  background-color: #1BFFFF;
  box-shadow: 0 0 20px #1BFFFF, 0 0 30px #1BFFFF, 0 0 40px #00ffcc;
}

.top-left::before, .bottom-left::before {
  width: 5px;
  height: 30px;
  top: 0;
  left: 0;
}

.top-left::after, .top-right::after {
  width: 30px;
  height: 5px;
  top: 0;
}

.top-right::before, .bottom-right::before {
  width: 5px;
  height: 30px;
  top: 0;
  right: 0;
}

.bottom-left::after, .bottom-right::after {
  width: 30px;
  height: 5px;
  bottom: 0;
}

.top-left {
  top: 0;
  left: -20%;
  width: 50px;
  height: 50px;
  
}

.top-right {
  top: 0;
  right: -40%;
  
}

.bottom-left {
  bottom: -10%;
  left: -20%;
 
}

.bottom-right {
  bottom: -10%;
  right: -40%;
  
}

.container-bg-anm {
  position: relative;
  width: 300px;
  height: 380px;
  display: flex;
  justify-content: center;
}

/* All boxes will have the same dimensions */
.box {
  position: absolute;
  width: 100%;
}

.box1 {
  background-color: #131314; /* Dark color */
  width: 100%;
}

.box2 {
  /* background-color: #2B2B2D; */
  background-color: #212123;
  position: relative;
  left: 6%;
  top: 18px;
  width: 100%;
}

.box3 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; 
  position: relative;
  left: 6%;
  top: 18px;
  width:100%;
}

.text {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width:1000px){
    .anm-img{
      margin-left: 0;
      margin-top: 0%;
      padding: 2%;
    }
    .container-bg-anm{
      width: 180px;
      height: 180px;
      margin-left: -10%;
    }
    .scanning-line{
      width: 200px;
      left: 15%;
    }
    .top-left{
      top: 25%;
      left: 5%;
    }
    .top-right{
      top: 25%;
      right: 3%;
    }
    .bottom-left{
      top: 90%;
      left: 5%;
    }
    .bottom-right{
      top: 90%;
      right: 3%;
    }
    .box2, .box3{
      top: 10px;
    }
    .border-container{
      height: 400px;
    }
}


@media (max-width: 568px){
  .anm-img{
    margin-left: 0;
    margin-top: 0%;
    padding: 2%;
  }
  .container-bg-anm{
    width: 180px;
    height: 180px;
    margin-left: -10%;
  }
  .scanning-line{
    width: 200px;
    left: 15%;
  }
  .top-left{
    top: 25%;
    left: 5%;
  }
  .top-right{
    top: 25%;
    right: 3%;
  }
  .bottom-left{
    top: 90%;
    left: 5%;
  }
  .bottom-right{
    top: 90%;
    right: 3%;
  }
  .box2, .box3{
    top: 10px;
  }
  .border-container{
    height: 400px;
  }
}


@media (max-width: 480px){
  .homeBanner-main{
    width: 100%;
   overflow-x: hidden;
  }
  .anm-img{
    margin-left: 0;
    margin-top: -8%;
    padding: 2%;
  }
  .container-bg-anm{
    width: 200px;
    height: 240px;
    margin-left: -10%;
  }
  .scanning-line{
    width: 200px;
    left: 15%;
  }
  .scan-img{
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  .top-left{
    top: 15%;
    left: -1%;
  }
  .top-right{
    top: 15%;
    right: -5%;
  }
  .bottom-left{
    top: 90%;
    left: -1%;
  }
  .bottom-right{
    top: 90%;
    right: -5%;
  }
  .box1{
    width: 100%;
    height: 100%;
  }
  .box2, .box3{
    top: 10px;
    width: 100%;
    height: 100%;
  }
  .border-container{
    height: 400px;
  }
}