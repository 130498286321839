.fifth-section {
  padding: 50px;
  text-align: center;
  background-color: #ffffff; /* Background color */
}

.fifth-section h1 {
  color: #fff;
  font-size: 36px;
  margin-bottom: 20px;
}

/* Comparison Table Styling */
.fifth-section .comparison-table {
  display: flex;
  justify-content: center;
  gap: 5%;
  padding: 40px 0;
  margin: 0 auto;
}

.fifth-section .comparison-column {
  border-radius: 15px;
  padding: 20px;
  min-width: 30%;
  background: linear-gradient(200deg, #00ffbb32, #415afc3b);
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.2), 0 8px 15px rgba(0, 183, 255, 0.3);
  border: #415afc5d solid 1px;
  color: #000000;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transform: perspective(500px) rotateY(0deg); /* Initial state */
}

.fifth-section .comparison-column:hover {
  transform: scale(1.1) rotateY(12deg);
  box-shadow: 0 12px 25px rgba(255, 255, 255, 0.5), 0 12px 25px rgba(0, 183, 255, 0.6);
}

.fifth-section .comparison-column h2 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 25px;
  color: #000000;
  padding: 15px;
  background: rgba(114, 218, 255, 0.2);
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(8, 0, 255, 0.4);
}

.fifth-section .free-icon {
  color: #4CAF50; /* Green for Free */
  margin-right: 10px;
  animation: bounce 2s infinite;
}

.fifth-section .premium-icon {
  color: #FFD700; /* Golden for Premium */
  margin-right: 10px;
  animation: pulse 2s infinite;
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fifth-section .checkmark {
  color: #4CAF50; /* Green checkmark */
}

.fifth-section .crossmark {
  color: red; /* Red crossmark */
}

/* List Styling */
.fifth-section .comparison-column ul {
  list-style-type: none;
  padding: 0;
  color: #000000;
  font-size: 18px;
}

.fifth-section .comparison-column ul li {
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  transition: color 0.3s ease;
}

.fifth-section .comparison-column ul li span {
  margin-right: 12px;
  font-size: 24px;
}

/* Download Button */
.fifth-section .download-button {
  background: linear-gradient(90deg, #15F5BA, #0066FF);
  border: none;
  color: white;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.fifth-section .download-button:hover {
  transform: scale(1.1);
  background: linear-gradient(90deg, #15F5BA, #0066FF);
}

/* Responsive Design */
@media (max-width: 1200px) {
  /* Tablet and smaller desktop */
  .fifth-section h1 {
    font-size: 32px;
  }

  .fifth-section .comparison-column {
    min-width: 35%; /* Adjust the size for medium screens */
  }

  .fifth-section .comparison-column h2 {
    font-size: 28px;
  }

  .fifth-section .comparison-column ul li {
    font-size: 18px;
  }

  .fifth-section .download-button {
    font-size: 16px;
    padding: 12px 24px;
  }
}

@media (max-width: 992px) {
  /* Tablet portrait */
  .fifth-section h1 {
    font-size: 28px;
  }

  .fifth-section .comparison-table {
    flex-wrap: wrap; /* Wrap columns */
    gap: 3%; /* Reduce gap */
  }

  .fifth-section .comparison-column {
    min-width: 45%; /* Take more width for smaller screens */
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .fifth-section .comparison-column h2 {
    font-size: 24px;
  }

  .fifth-section .comparison-column ul li {
    font-size: 16px;
  }

  .fifth-section .free-icon, .fifth-section .premium-icon {
    font-size: 20px;
  }

  .fifth-section .download-button {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  /* Mobile devices */
  .fifth-section {
    padding: 40px;
  }

  .fifth-section h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .fifth-section .comparison-table {
    display: block; /* Stacks the columns */
    padding: 20px 0;
  }

  .fifth-section .comparison-column {
    min-width: 100%;
    margin-bottom: 25px;
  }

  .fifth-section .comparison-column h2 {
    font-size: 20px;
  }

  .fifth-section .comparison-column ul li {
    font-size: 14px;
  }

  .fifth-section .free-icon, .fifth-section .premium-icon {
    font-size: 18px;
  }

  .fifth-section .download-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  /* Extra small mobile */
  .fifth-section h1 {
    font-size: 20px;
  }

  .fifth-section .comparison-column h2 {
    font-size: 18px;
  }

  .fifth-section .comparison-column ul li {
    font-size: 12px;
  }

  .fifth-section .download-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
