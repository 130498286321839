/* Flex container for intro and testimonial sections */
.testimonial-container {
    display: flex;
    justify-content: space-between;
    gap: 10%;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 4% 3%;
    position: relative;
    overflow: hidden;
  }
  
  /* Section 1: Intro content */
  .testimonial-intro {
    flex: 1;
  }

  .testimonial-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .testimonial-intro h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    color: black;
  }
  
  .testimonial-intro p {
    font-size: 1.1rem;
    color: #414040;
    line-height: 1.6;
  }
  
  /* Section 2: Testimonial Slider */
  .testimonial-slider {
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .testimonial-box {
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    overflow: hidden;
    background-color: white;
    border-left: 4px solid black;
    border-radius: 8px;
    padding-top: 2%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .testimonial-content-box {
    position: absolute;
    padding: 20px;
    text-align: center;
    max-width: 500px;
    height: 250px;
    
  }
  
  .testimonial-content {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    font-style: italic;
    height: 20vh;
  }
  
  .testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .testimonial-title {
    font-size: 1rem;
    color: #555;
  }
  
  .next-button {
    position: absolute;
    right: 0px;
    top: 93%;
    transform: translateY(-50%);
    background-color: black;
    color: #15F5BA;
    border: none;
    padding: 15px 15px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .next-button:hover {
    background-color: rgb(48, 47, 47);
    transform: translateY(-50%) scale(1.05); /* Slight scale on hover */
  }

  /* responsiveness */
  @media(max-width:600px){
    .testimonial-intro h2{
      font-size: 24px;
    }
    .testimonial-container{
      display: block;
    }
    .testimonial-intro p{
      font-size: 15px;
    }
    .testimonial-intro{
      margin-bottom: 10%;
    }
    .testimonial-content{
      font-size: 18px;
      line-height: 20px;
      height: 25vh;
      margin-bottom: 60px;
    }
    .testimonial-name{
      font-size: 14px;
    }
    .testimonial-title{
      font-size: 13px;
    }
    .testimonial-img{
      width: 40px;
      height: 40px;
    }
    .testimonial-content{
      margin-bottom: 0;
    }
  }
  
  
  /* responsiveness */
  @media(max-width:480px){
    .testimonial-intro h2{
      font-size: 18px;
    }
    .testimonial-container{
      display: block;
    }
    .testimonial-intro p{
      font-size: 15px;
    }
    .testimonial-intro{
      margin-bottom: 10%;
    }
    .testimonial-content{
      font-size: 18px;
      line-height: 20px;
      height: 25vh;
      margin-bottom: 60px;
    }
    .testimonial-name{
      font-size: 14px;
    }
    .testimonial-title{
      font-size: 13px;
    }
    .testimonial-img{
      width: 40px;
      height: 40px;
    }
    .testimonial-content{
      margin-bottom: 0;
    }
  }
  
  