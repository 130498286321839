.home-ul{
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  padding-right: 3%;
 height: 100vh;
 margin-top: -3%;
}
.banner-intro{
margin-left: -5%;
}
.discount{
  color: white;
  padding: 1% 2%;
  background: rgba( 255, 255, 255, 0.2 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 5px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 390px;
}
.typing-anm{
  color: white;
  font-size: 4rem;
}
.highlight{
  background: linear-gradient(90deg, #15f5b9d0, #002affc4);
-webkit-background-clip: text; /* For Safari */
-webkit-text-fill-color: transparent; /* For Safari */
background-clip: text; /* Standard */
color: transparent;
}
.banner-p{
  color: rgb(174, 168, 168);
  line-height: 30px;
  font-size: 18px;
  font-weight: 400;
}
.gs-btn {
  background: linear-gradient(45deg, #15f5b9d0, #002affc4);
  color: #fff;
  padding: 2% 4%;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  box-sizing: border-box;
  transition: background 0.4s ease;
}

.gs-btn:hover {
  background: linear-gradient(185deg, #15f5b9d0, #002affc4);
  transition: background 0.4s ease;
  
}

.span-highlight {
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.helo{
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1%;
  color: aqua;
  position: relative;
  top: 115%;
  font-size: 20px;
  width: 500px;
  text-align: center;
  font-weight: 500;
  font-family: none;
  margin-left: 20%;
}

.scan-img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.loader-container {
  display: flex;
  gap: 1%;
  height: auto; /* Full screen height */
  margin-top: 10px;
}

.stopProgressLoader{
  display: none;
}

.pulse1, .pulse2, .pulse3 {
  width: 8px;
  height: 8px;
  background-color: aqua;
  border-radius: 50%; /* Make it circular */
  display: inline-block;
  margin: 0 2px; /* Space between pulses */
  animation: pulse-animation 1.5s infinite ease-in-out; /* Same duration for all */
}

.pulse2 {
  animation-delay: 0.3s; /* Delay to start after pulse1 */
}

.pulse3 {
  animation-delay: 0.6s; /* Delay to start after pulse2 */
}

@keyframes pulse-animation {
  0% {
    transform: scale(1); /* Initial size */
    opacity: 1; /* Full opacity */
  }
  50% {
    transform: scale(1.5); /* Grow to 1.5 times the size */
    opacity: 0.5; /* Fade opacity slightly */
  }
  100% {
    transform: scale(1); /* Return to original size */
    opacity: 1; /* Full opacity again */
  }
}

/* counting element */

.counting-main{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  background: radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(126, 86, 86) 90.2%);
  padding: 3% 2%;
}
.count-div{
  text-align: center;
  padding: 20px;
}
.count-div h1{
  font-size: 40px;
}
.count-div p{
  background: linear-gradient(90deg, #15F5BA, #0066ff);
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 20px;
font-weight: 600;
}

.func{
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5%;
}
.func li h3{
 color: white;
}

/* feature section 1 */
.feature-ul-1{
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6% 6%;
}
.feature-li-1, .feature-li-2{
  width: 50%;
}
.feature-li-1 h2{
  color: black;
font-size: 40px;
font-weight: 600;
}
.feature-li-1 p{
  color: rgb(73 68 68);
  font-size: 18px;
  line-height: 30px;
}
.feature-li-2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10%;
}
.fcard-1, .fcard-2, .fcard-3{
  width: 230px;
  height: 260px;
  background-color: rgb(120, 115, 115);
  border-radius: 8px;
  padding: 3%;
  cursor: pointer;
}
.fcard-1 p, .fcard-2 p, .fcard-3 p{
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(179, 176, 176);
}
.fcard-1 ul, .fcard-2 ul, .fcard-3 ul{
  padding-top: 10%;
  margin-left: 6%;
}
.fcard-1 ul li, .fcard-2 ul li, .fcard-3 ul li{
  margin-bottom: 8%;
  font-size: 15px;
}
.fcard-2 p{
  border-bottom: 1px solid rgb(137, 134, 134);
}
.fcard-1{
  background-color: rgb(244, 238, 238);
}
.fcard-3{
  background-color: #615c5c;
  color: white;
  margin-top: -10%;
  z-index: 2;
}
.fcard-2{
  background-color: rgb(204, 202, 202);
}
.fcard-1:hover, .fcard-2:hover{
  z-index: 2;
}


@media (max-width : 1000px){
  .banner-intro{
    padding: 2%;
    margin: auto;
    margin-left: 5%;
    margin-right: 100px;
  }
  .discount{
    font-size: 4px;
    width: auto;
    margin-top:10%;
  }
  .typing-anm{
    font-size: 4vw;
    text-align: center;
  }
  .scan-img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .func-img{
    width: 60%;
  }
}


@media (max-width: 568px){
  .home-ul{
    display: block;
    margin-top: 0;
  }
  .discount{
    font-size: 11px;
    width: auto;
    margin-top:10%;
  }
  .typing-anm{
    font-size: 10vw;
    text-align: center;
  }
  .banner-p{
    font-size: 15px;
    line-height: 23px;
    text-align: center;
  }
  .gs-btn{
    font-size: 14px;
    border-radius: 5px;
    margin-left: 37%;
  }
  .banner-intro{
    padding: 5%;
    margin: auto;
    margin-left: 5%;
  }
  .func{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 65%;
  }
  .func-img{
    width: 60%;
  }
  .func li h3{
    font-size: 16px;
    font-weight: 400;
  }
  .helo{
    font-size: 17px;
    top: 140%;
  }
  .feature-ul-1{
    display: block;
  }
  .feature-li-1{
    width: 100%;
  }
  .feature-li-2{
    width: 100%;
    margin-top: 3%;
  }
  .counting-main{
    display: block;
    justify-content: center;
  }
  .count-div h1{
    font-size: 20px;
  }
  .count-div p{
    font-size: 15px;
  }
  .fcard-1, .fcard-2, .fcard-3{
    width: 200px;
    height: 220px;
  }
  .feature-li-1 h2{
    font-size: 23px;
  }
  .feature-li-1 p{
    line-height: 25px;
    font-size: 15px;
  }
  .pulse1, .pulse2, .pulse3{
    width: 5px;
    height: 5px;
  }
}



@media (max-width: 480px){
  .home-ul{
    display: block;
    margin-top: 0;
  }
  .discount{
    font-size: 11px;
    width: auto;
    margin-top:10%;
  }
  .typing-anm{
    font-size: 10vw;
    text-align: center;
  }
  .banner-p{
    font-size: 15px;
    line-height: 23px;
    text-align: center;
  }
  .gs-btn{
    font-size: 14px;
    border-radius: 5px;
    margin-left: 30%;
  }
  .banner-intro{
    padding: 5%;
    margin: auto;
    margin-left: 5%;
  }
  .func{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 60%;
  }
  .func-img{
    width: 60%;
  }
  .func li h3{
    font-size: 16px;
    font-weight: 400;
  }
  .helo{
    font-size: 17px;
    top: 115%;
  }
  .feature-ul-1{
    display: block;
  }
  .feature-li-1{
    width: 100%;
  }
  .feature-li-2{
    width: 100%;
    margin-top: 3%;
  }
  .counting-main{
    display: block;
    justify-content: center;
  }
  .count-div h1{
    font-size: 20px;
  }
  .count-div p{
    font-size: 15px;
  }
  .fcard-1, .fcard-2, .fcard-3{
    width: 200px;
    height: 220px;
  }
  .feature-li-1 h2{
    font-size: 23px;
  }
  .feature-li-1 p{
    line-height: 25px;
    font-size: 15px;
  }
  .pulse1, .pulse2, .pulse3{
    width: 5px;
    height: 5px;
  }
}