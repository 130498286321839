.portfolio-slider-section {
    width: 80%;
    padding: 60px 0;
    text-align: center;
    margin:auto;
}
.portfolio  {
    font-size: 1.5rem;
    color:black;
    font-weight:600;
}
.portfolio-title  {
    font-size: 2.5rem;
   color:black;
    margin-bottom: 20px;
}
.portfolio-slider-section span {
    font-size: 2.5rem;
    background: linear-gradient(90deg, #15f5b9d0, #002affc4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
   
}


.portfolio-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

/* .portfolio-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
} */

.portfolio-card-image {
    width: 100%;
    height: 450px !important;
    object-fit: cover;
    filter: brightness(0.8);
    border-radius: 12px;
}

.portfolio-card-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    color: #fff;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    text-align: left;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.portfolio-div1 {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px; 
    padding: 8px 12px; 
    display: inline-block; 
}

/* Responsive adjustments for tablet view (768px - 1024px) */
@media (max-width: 1024px) {
    .portfolio-slider-section {
        width: 90%;
    }
    .portfolio {
        font-size: 1.3rem;
    }
    .portfolio-title {
        font-size: 2rem;
    }
    .portfolio-card-image {
        height: 400px !important;
    }
}

/* Responsive adjustments for mobile view (up to 768px) */
@media (max-width: 768px) {
    .portfolio-card
    {
        width:300px;
    }
    .portfolio-slider-section {
        width: 95%;
        padding: 40px 0;
    }
    .portfolio {
        font-size: 1.2rem;
    }
    .portfolio-title {
        font-size: 1.8rem;
    }
    .portfolio-card-subtitle
    {
        font-size:1.3rem!important;
    }
    .portfolio-slider-section span {
        font-size: 1.8rem;
    }
    .portfolio-card-image {
        height: auto;
       
    }

    .portfolio-card-overlay {
        padding: 15px;
    }
   
}

/* .portfolio-div1 :hover {
    background-color: #ffffff;
    color:black;
} */

.portfolio-card-title {
    font-size: 1rem; 
    color: #fff; 
    margin: 0; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}
.portfolio-card-subtitle
{
    margin-top:30px;
    font-size: 1.3rem;
    font-weight: 600; /* Bold text */
    margin-bottom:20px;
}

.portfolio-card-arrow {
    font-size: 1.5rem;
    font-weight: 600;
     padding-bottom:3px;
    bottom: 10px;
    right: 10px;
    border-radius: 50%;
    border: 0.5px solid white;
    color: white;
    width: 50px; 
    height: 50px; 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;

}

.portfolio-card:hover .portfolio-div1 {
    background-color: rgba(255, 255, 255, 0.9); 
   
}
.portfolio-card:hover .portfolio-card-title
{
    color: #333;
}

.portfolio-card:hover .portfolio-card-arrow {
    background-color: white;
    color: #333;
    border: 0.5px solid #333; 
}
.modal-close:hover
{
    color:#15f5ba;
}

.slick-prev, .slick-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    /* background: #15F5BA;
    border-radius: 50%; */
    z-index: 1;
}

.slick-dots li button:before {
    color: #15F5BA;
}

.slick-dots li.slick-active button:before {
    color: #0066FF;
}

/* Modal overlay */
 .overlay {
    background-color: rgba(0, 0, 0, 0.5)!important;
 }
 

/* Modal content */
/* Base Modal Styles */
.modal-content {
    display: flex;
    align-items: center;
}

.modal {
    background: white;
    width: 60%;
    max-width: 1000px;
    padding: 30px 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.modal-image {
    width: 40%;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
}

.modal-text {
    flex: 1;
    padding-left: 20px;
}

.popupbox-card-subtitle {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
}

.modal-text p {
    font-size: 1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 15px;
}

.modal-text ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #666;
    font-size: 1rem;
}

.modal-text li {
    margin-bottom: 10px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2.5rem;
    font-weight: 600;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #333;
}

/* Tablet View Adjustments (max-width: 1000px) */
@media (max-width: 1000px) {
    .modal {
        width: 80%;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    .modal-content {
        flex-direction: column;
    }

    .modal-image {
        width: 92%;
        margin: 0 0 20px 0;
        height: 400px;
    }

    .modal-text {
        padding-left: 0;
        text-align: left;
    }

    .popupbox-card-subtitle {
        font-size: 1.5rem;
        margin-top: 20px;
        text-align: left;
    }

    .modal-text p, .modal-text ul, .modal-text li {
        font-size: 1rem;
        text-align: left;
        line-height: 1.5;

    }

    .modal-close {
        top: 5px;
        right: 10px;
        font-size: 2rem;
    }
}

/* Mobile View Adjustments (max-width: 768px) */
@media (max-width: 768px) {
    .modal {
        width: 80%;
        height:auto;
        padding: 20px;
        flex-direction: column;
        align-items: center;
    }

    .modal-image {
        width: 92%;
        height:300px;
        margin-bottom: 15px;
    }

    .popupbox-card-subtitle {
        font-size: 1.3rem;
        margin-top:5px;
        margin-bottom:5px;
        text-align:left;
    }

    .modal-text p, .modal-text ul, .modal-text li {
        font-size: 0.9rem;
        line-height: 1.4;
        text-align:left;
    }

    .modal-close {
        font-size: 1.8rem;
        top: 5px;
        right: 10px;
    }
}

/* Small Mobile View Adjustments (max-width: 480px) */
@media (max-width: 480px) {
    .modal {
        width: 95%;
        padding: 15px;
    }
    .portfolio-slider-section{
        padding: 10% 3%;
    }

    .popupbox-card-subtitle {
        font-size: 1.2rem;
        text-align:left;
    }

    .modal-text p, .modal-text ul, .modal-text li {
        font-size: 0.85rem;
        text-align:left;

    }

    .modal-close {
        font-size: 1.5rem;
        top: 5px;
        right: 10px;
    }
    .portfolio-card{
       margin: auto;
    }
}

