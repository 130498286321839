/* General blog layout styles */
.blog-details-container {
  background-color: white;
  margin-top: 10%;
}

.blog-container {
  width: 60%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  margin-top: 5%;

  /* Author info */
  .blog-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0px;
    border-top: 1px solid rgba(173, 173, 173, 0.467);
    border-bottom: 1px solid rgba(173, 173, 173, 0.467);
  }

  .blog-info span {
    margin-right: 10px;
  }

  .author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .author-name,
  .blog-date,
  .read-time {
    font-size: 14px;
    color: #555;
    margin-right: 5px;
  }

  /* Title and subtitle */
  .blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 20px 0;
    color: #333;
  }

  .blog-subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666;
  }

  /* Blog image styles */
  .blog-image-container {
    margin: 20px 0;
  }

  .share-icon {
    font-size: 16px;
    color: #555;
    cursor: pointer;
  }

  .share-icon:hover {
    color: #000;
  }

  .blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  /* Blog content */
  .blog-content {
    margin-top: 20px;
  }

  .blog-content h2 {
    font-weight: 700;
    margin: 40px 0px;

  }

  .blog-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }


  .text-with-border {
    background: #5555552f;
    border-left: #000 5px solid;
    font-weight: bold;
    padding: 30px;
    margin: 20px 0px;
  }

  .hashtag {
    color: #0073e6;
    /* Change color to differentiate */
    font-weight: bold;
    /* Make it bold */
    cursor: pointer;
    /* Make it look clickable */
  }

  .hashtag:hover {
    color: #005bb5;
    /* Darker shade on hover */
    text-decoration: underline;
    /* Underline on hover */
  }
}

/* Recent post section */
.recent-posts-section {
  text-align: center;
  padding: 20px;
  max-width: 88%;
  /* Adjusted to 80% */
  margin: 0 auto;
  /* This centers the section horizontally */
  overflow-x: hidden;

  h2 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  .posts-container {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
    margin: 20px 0px;
    gap: 20px;
  }

  .post-card {
    flex: 0 0 300px;
    /* Adjust width as needed */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .post-card:hover {
    transform: translateY(-5px);
  }

  .post-card img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }

  .post-card h3 {
    font-size: 20px;
    margin: 10px 0;
    color: black;
    text-decoration: none;
    height:50px;
  }

  .post-card a {
    text-decoration: none;
    color: inherit;
    /* Ensures the link inherits the color from the h3 */
  }

  .post-card a:hover {
    text-decoration: none;
  }

  .post-stats {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adjusts spacing between the views and likes */
  }

  .post-stats span {
    display: flex;
    align-items: center;
    font-size: 18px;
    /* Adjust the size as needed */
    gap: 5px;
  }

  .post-stats .ai-outline-heart {
    color: red;
    /* Sets the heart icon color to red */
    font-size: 20px;
    /* Increases the size of the heart icon */
    margin-right: 5px;
    /* Adds space between the heart icon and the number */

  }


  .post-stats {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px;
  }

  .posts-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .posts-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }

  .posts-container::-webkit-scrollbar-track {
    background-color: transparent;
  }


}

/* Media Queries for responsiveness */

/* For tablet screens (768px and below) */
@media (max-width: 1120px) {
  .blog-container {
    width: 80%;
  }

}

@media (max-width: 768px) {
  .blog-container {
    width: 80%;
    margin-top: 20%;

    .blog-title {
      font-size: 2rem;
    }

    .blog-subtitle {
      font-size: 1rem;
    }

    .blog-content p {
      font-size: 1rem;
    }
  }

  .post-card {
    flex: 0 0 200px;
  }

  .post-card h3 {
    font-size: 18px;
  }

  .post-card img {
    height: 140px;
  }
}

/* For mobile screens (480px and below) */
@media (max-width: 480px) {
  .blog-container {

    margin-top: 25%;
  }

  .blog-title {
    font-size: 1.5rem;
  }

  .blog-subtitle {
    font-size: 0.9rem;
  }

  .blog-content p {
    font-size: 0.9rem;
  }

  .text-with-border {
    padding: 20px;
    font-size: 0.9rem;
  }

  .recent-posts-section h2 {
    font-size: 20px;
  }

  .post-card {
    flex: 0 0 100px;
  }

  .post-card h3 {
    font-size: 16px;
  }

  .post-card img {
    height: 50px;
    width: 50px;
  }

  .post-stats {
    font-size: 12px;
  }
}

/* Style for "Show All" link */
.recent-posts-section h4 {
  background-color: #2600ff;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
}

.recent-posts-section h4:hover {
  background: linear-gradient(90deg, #00ffbb4a, #2600ff);
  /* Lighter color on hover */
}

/* Add arrow next to the "Show All" text */
.recent-posts-section h4::after {
  font-size: 18px;
  position: absolute;
  right: -20px;
  top: 0;
  transition: right 0.3s ease;
}

/* Move arrow when hovering over "Show All" */
.recent-posts-section h4:hover::after {
  right: -30px;
  /* Move arrow further right on hover */
}

/* Style for the post-stats section */
.post-stats {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.post-stats span {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #393e46;
  /* Dark text color */
}

/* Style for the Like button (heart icon) */
.post-stats span:nth-child(2) {
  cursor: pointer;
  transition: transform 0.2s ease;
  gap: 5px;
}

/* Like button hover effect (scale and color change) */
.post-stats span:nth-child(2):hover {
  color: #d65a31;
  /* Change color on hover */
  transform: scale(1.2);
  /* Enlarge heart icon slightly */
}