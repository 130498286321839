/* Existing CSS */
:root {
  --e-global-color-primary: #1f1f1f; /* Dark Gray */
  --e-global-color-secondary: #5a2dc9; /* Bright Purple */
  --e-global-color-text: #d0d0d0; /* Light Gray */
  --e-global-color-accent: #57eef1; /* Cyan */
  --e-global-color-white: #ffffff; /* White */
  --e-global-color-black: #000000; /* Black */
  --e-global-color-dark-gray: #171717d8; /* Dark Gray */
  --e-global-color-grayish-blue: #a2abbd; /* Grayish Blue */
  --e-global-color-light-gray: #dedede; /* Light Gray */
  --e-global-color-pale-blue: #f1f0ff; /* Pale Blue */
  --e-global-color-bright-yellow: #fbc729; /* Bright Yellow */
}

.chooseus_section_6_unique {
  padding: 50px;
  border-radius: 10px;
  color: white;
}

.chooseus_section_6_content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left; /* Align text to the left for better readability */
}

.chooseus_section_6_unique .section_title {
  font-size: 2.4rem;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #15F5BA, #0066FF);
  -webkit-background-clip: text; /* Makes the background clip to the text */
  -webkit-text-fill-color: transparent; /* Makes the text color transparent to show the gradient */
}

/* Mobile-specific styles */
.tabs {
  display: none; /* Hide tabs on mobile by default */
}

/* Desktop-specific styles */
@media (min-width: 768px) {
  .tabs {
    display: flex;
    justify-content: flex-start; /* Align tabs to the start */
    gap: 20px; /* Space between tabs */
    margin-bottom: 20px; /* Space below the tabs */
  }
}

.tab {
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 10px 20px; /* Padding for spacing */
  border-bottom: 2px solid transparent; /* Transparent border for inactive tabs */
  font-weight: bold; /* Bold text for tabs */
  transition: color 0.3s, border-color 0.3s; /* Smooth transition for color changes */
}

.tab:hover {
  color: var(--e-global-color-accent); /* Change color on hover */
  border-color: var(--e-global-color-accent); /* Show accent color when hovered */
}

.tab.active {
  color: var(--e-global-color-accent); /* Color for active tab */
  border-color: var(--e-global-color-accent); /* Show accent color for active tab */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow to the active tab for emphasis */
}


.content_box {
  display: flex; /* Use flexbox to create the two-column layout */
  justify-content: space-between; /* Space between text and image */
  align-items: flex-start; /* Align items at the top */
}

.text_area {
  flex: 3; /* 60% of the space */
  padding-right: 20px; /* Space between text and image */
}

.image_area {
  flex: 2; /* 40% of the space */
  position: relative; /* Set position to relative for absolute positioning of child elements */
  padding: 20px; /* Add padding if needed */
  border-radius: 10px; /* Optional: rounded corners */
  overflow: hidden; /* Hide any overflow for a clean look */

}

.image_area img {
  max-width: 80%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  margin-top: 10px;
}

.shape_image {
  position: absolute; 
  top: -10px; 
  right: 50px; 
  width: 100px;
  height: auto;
}

.requirements h3 {
  font-size: 1.5rem;
}

.requirements ul {
  list-style: disc;
  padding-left: 40px;
  text-align: left; /* Keep list items left-aligned */
}

.requirement_title, .os_title {
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: var(--e-global-color-accent); /* Accent color for titles */
}

.requirement_list {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove padding */
}

.requirement_list li {
  padding: 5px 0; /* Space between list items */
  font-size: 1.2rem;
  color: var(--e-global-color-text); /* Light Gray for list items */
}

.requirement_bullet {
  color: var(--e-global-color-bright-yellow); /* Bright color for bullets */
  font-weight: bold;
  font-size: 1.5rem; /* Slightly larger bullet */
}

.requirement_storage,
.requirement_note,
.os_description {
  font-size: 1.1rem;
  margin-top: 10px;
  color: var(--e-global-color-text); /* Light Gray */
}

.requirement_note {
  font-style: italic; /* Italic style for note */
  color: var(--e-global-color-grayish-blue); /* Grayish Blue for notes */
}

.windows_content, .linux_content {
  padding: 20px; /* Add padding to these sections */
  background: rgba(255, 255, 255, 0.1); /* Same light background */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

/* Download Button Styles */
.download_button {
  background: linear-gradient(120deg, #15f5b9c5, #0066FF);
  color:white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 15px; /* Space above button */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.download_button:hover {
  background-color: var(--e-global-color-bright-yellow); /* Change to bright yellow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.download_button:active {
  transform: translateY(0); /* Reset lift effect */
}


/* Mobile Styles */
@media (max-width: 768px) {
  .chooseus_section_6_unique {
    padding: 20px; /* Reduce padding for mobile */
  }

  .section_title {
    font-size: 1.8rem; /* Smaller title size for mobile */
  }

  .tabs {
    flex-direction: column; /* Stack tabs vertically */
    gap: 10px; /* Reduce gap between tabs */
  }

  .tab {
    padding: 10px; /* Adjust padding for tabs */
    text-align: center; /* Center text in tabs */
  }

  .content_box {
    flex-direction: column; /* Stack text and image vertically */
    align-items: center; /* Center align items */
  }

  .text_area {
    flex: none; /* Allow text area to take full width */
    padding: 0; /* Remove padding */
    margin-bottom: 20px; /* Space below text area */
  }

  .image_area {
    flex: none; /* Allow image area to take full width */
    padding: 0; /* Remove padding */
    text-align: center; /* Center the image */
  }

  .image_area img {
    max-width: 80%; /* Ensure image is responsive */
    height: auto; /* Maintain aspect ratio */
  }

  .requirement_title, .os_title {
    font-size: 1.4rem; /* Smaller title size */
  }

  .requirement_list li {
    font-size: 1rem; /* Smaller list item size */
  }

  .requirement_storage, .requirement_note, .os_description {
    font-size: 0.9rem; /* Smaller text for additional info */
  }

  .download_button {
    width: 100%; /* Make buttons full width */
    font-size: 1rem; /* Adjust button text size */
    padding: 10px; /* Adjust button padding */
  }
  
.shape_image{
  right: 7px;
  top: -10px
}
}
