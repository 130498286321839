/* InquiryForm.css */

.consultation-section-i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .consultation-container-i {
    position: relative;
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }
  
  .close-button-i {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }

  .close-button-i:hover{
    color: #15F5BA;
  }
  
  .form-title-i {
    font-size: 1.75rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .form-desc-i {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .center-container-i {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .success-message-i {
    font-size: 1rem;
    color: #28a745;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .consultation-form-i {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-row-i {
    display: flex;
    gap: 1rem;
  }
  
  .form-left-i, .form-right-i {
    flex: 1;
  }
  
  .input-group-i {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .input-field-i {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  textarea.input-field-i {
    min-height: 100px;
    resize: vertical;
  }
  
  .error-message-i {
    color: #e74c3c;
    font-size: 0.875rem;
  }
  
  .submit-button-i {
    padding: 0.75rem;
    font-size: 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: linear-gradient(90deg, #15F5BA, #0066ff);
  }
  
  .submit-button-i:hover {
    background: linear-gradient(90deg, #10c595, #0049b8);
  }


  /* Responsive styles for mobile view */
@media (max-width: 600px) {
  .consultation-container-i {
    padding: 1.5rem; /* Reduced padding for mobile */
    width: 95%; /* Increase width for smaller screens */
  }

  .form-title-i {
    font-size: 1.5rem; /* Smaller title font size */
  }

  .form-desc-i {
    font-size: 0.9rem; /* Smaller description font size */
  }

  .input-field-i {
    font-size: 0.9rem; /* Smaller input field font size */
    padding: 0.5rem; /* Reduced padding */
  }

  .submit-button-i {
    font-size: 18px; /* Smaller button font size */
  }

  .success-message-i {
    font-size: 0.9rem; /* Smaller success message font size */
  }

  .error-message-i {
    font-size: 0.8rem; /* Smaller error message font size */
  }
}