/* form container section */
.consultation-section{
    background: linear-gradient(to right, #010b14, #000000);
     padding: 5% 3% 2% 3%;
    border-bottom: 1px solid rgb(59 56 56);
}
.consultation-container {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    max-width: 1200px; /* Set a max width for the form container */
    margin: auto; /* Center the container */
    box-shadow: 0 0 1px 1px rgba(19, 217, 243, 0.822), 
    0 0 5px 1px rgb(45, 236, 61);

  /* Optional: Add a background gradient to the border area */
  background-clip: padding-box;
    font-family: 'Jost', sans-serif;
    margin-bottom:3%;
    width: 70%;
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
  }

  .success-message {
    color: green;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
    display: inline;
    padding: 0% 2%;
  }

  .center-container {
    display: flex;                
    justify-content: center;  
  }
  



.form-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    background: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
  -webkit-background-clip: text; /* For Safari */
  -webkit-text-fill-color: transparent; /* For Safari */
  background-clip: text; /* Standard */
  color: transparent;
    text-align: center;
}

.form-desc {
    margin-bottom: 2%;
    color:#4f4b4b;
    font-size: 18px;
    text-align: center;
    padding-left: 3%;
    padding-right: 3%;
}

.consultation-form {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.form-left,
.form-right {
    width: 48%; /* Use percentages to allow for responsiveness */
}

.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Jost', sans-serif;
}

.cf-inp {
    padding: 1% 2%;
    margin: 15px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    color: #979797;
}

textarea {
    height: 100px; /* Adjust height as needed */
}

.submit-button {
    width: 100%; /* Set the button to take full width of its container */
    max-width: 230px; /* Adjust the max-width to match your design */
    padding: 0.4% 1%;
    background: linear-gradient(90deg, #15F5BA, #0066ff);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.4rem;
    margin: 20px auto;
    font-family: 'Jost', sans-serif;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .consultation-container {
        margin: 0 10px; /* Add left and right padding on small screens */
    }

    .form-left,
    .form-right {
        width: 100%; 
        margin: 0;
    }

    .form-row {
        flex-direction: column; 
    }
  
    .form-desc
    {
        text-align: justify;
    }

    .submit-button {
        max-width: 100%; 
    }
}

@media (max-width: 480px) {
    .form-title {
        font-size: 20px; 
    }

    .form-desc {
        font-size: 15px; 
        text-align: center;
    }

    .cf-inp {
        font-size: 15px; 
        margin: 5px 10px;
        width: 90%;
    }
    .consultation-container{
        width: 90%;
        padding: 10px;
        margin-bottom: 5%;
    }
    .submit-button {
        max-width: 90%;
        font-size: 18px;
        padding: 3%;
    }
}